import NavBar from '../ui/Navbar';

function Header() {
  return (
    <>
      <NavBar />
    </>
  );
};

export default Header;
